<template>
  <div class="row">
    <transition name="fade">
      <div v-if="error && error.message" class="col-xl-12">
        <div class="alert alert-danger w-100" role="alert">
          <span class="alert-main-text">Error!</span> {{ error.message }}
        </div>
      </div>
    </transition>
    <div
      class=""
      :class="{
        'col-xl-9  mb-2 p-2': format != 'compact',
        'col-xl-12': format == 'compact',
      }"
      :id="session.SessionCode"
    >
      <router-link
        v-if="format != 'compact'"
        class="reg-text-link-light-bg"
        :to="{ name: 'SessionDetails', params: { id: session.id } }"
  
      >
        <p class="card-title-sm reg-text-link-light-bg pb-1">
          {{ session.SessionCode + " - " + session.Name }}
        </p>
      </router-link>

      <p v-else>{{ session.SessionCode + " - " + session.Name }}</p>
      <session-time-string
        :session="session"
        v-if="format != 'compact' && !isCmeSingleDay"
      ></session-time-string>
    </div>
    <div
      :class="{
        'col-xl-3': format != 'compact',
        'col-xl-12': format == 'compact',
      }"
      v-if="session.MaxCredits != 0"
    >
      <div class="row" v-if="!confirmDelete">
        <div class="col-lg-12 col-6 p-1">
          <button
            @click="
              addOrEditNavigation(
                doesSessionHaveClaim(session.SessionCode) ? 'Edit' : 'Add'
              )
            "
            class="btn btn-primary-solid w-100 mb-2"
          >
            <span v-if="format == 'compact'">{{
              doesSessionHaveClaim(session.SessionCode) ? "Edit" : "Claim CME"
            }}</span>
            <span v-else>{{
              doesSessionHaveClaim(session.SessionCode) ? "Edit" : "Add"
            }}</span>
          </button>
        </div>
        <div class="col-lg-12 col-6 p-1">
          <a
            class="btn btn-warning-outline w-100 mb-2"
            v-if="doesSessionHaveClaim(session.SessionCode)"
            @click="confirmDeleteCME(session.SessionCode)"
            href="#/"
          >
            Delete
          </a>
        </div>
      </div>

      <div :class="{ 'col-xl-12': format == 'compact' }" class="row" v-else>
        <div class="col-lg-12 col-md-6 p-1">
          <button
            class="btn btn-warning-outline w-100 mb-2 px-0"
            @click="deleteCME(session.SessionCode)"
          >
            Confirm Delete
          </button>
        </div>
        <div class="col-lg-12 col-md-6 p-1">
          <a
            class="btn btn-primary-solid w-100 mb-2 px-0"
            @click="cancelDelete()"
            href="#/"
          >
            Cancel
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import SessionTimeString from "@/components/SessionTimeString.vue";


export default {
  props: ["session", "format"],
  data() {
    return {
      error: {
        message: "",
      },
      confirmDelete: false,
    };
  },
  components: { SessionTimeString },
  methods: {
    addOrEditNavigation(operation) {
      let app = this;
      let payload = { sessionId: app.session.id, operation: operation };

      // Check user verified, date and credit amount
      let response = this.checkSessionCreditClaimStatus(payload);

      if (response.valid) { 
      
        console.log("Session", this.session, this.session.SessionCode);
        // Redirect
        this.$router.push({
          name: "CMESurvey",
          params: { sessionCode: this.session.SessionCode },
        });
      } else {
        this.error.message = response.message;

     

        let cmeCardComponent = this;
        // Remove error after 10 seconds
        setTimeout(() => {
          cmeCardComponent.error.message = "";
        }, 10000);
      }
    },
    doesSessionHaveClaim(sessionCode) {
      return this.isCreditClaimActiveBySessionCode(sessionCode);
    },
    confirmDeleteCME() {
      this.confirmDelete = true;
    },
    cancelDelete() {
      

      this.confirmDelete = false;
    },
    deleteCME(sessionCode) {
      let payload = {
        MeetingCode: this.getMeetingCode,
        SessionCode: sessionCode,
        RegCode: this.loggedInUser.registrationCode,
        RegHash: this.loggedInUser.registrationHash,
      };

      // Delete from server
      // Submit to server
      fetch(`https://meetings.aad.org/evaluations/evaluationapi/delete/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).then((response) => {
      
        if (response.status == 200) {
          // If successful, commit to firestore
          // Dispatch method to save to firestore
          this.$store
            .dispatch("deleteAttendeeCMEClaim", payload)
            .then(() => (this.confirmDelete = false));

        
        }
      });
    },
   
    
  },
  computed: {
    ...mapGetters([
      "allSessions",
      "getSessionById",
      "getSpeakerById",
      "allSpeakers",
      "isLoggedIn",
      "getMeetingCode",
      "loggedInUser",
      "currentMeeting",
      "isCreditClaimActiveBySessionCode",
      "isLoggedInUserVerified",
      "checkSessionCreditClaimStatus",
    ]),
    ...mapState(["isCmeSingleDay"]),
  },
  watch: {},
};
</script>


<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
